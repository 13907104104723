var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search" },
    [
      _c("el-input", {
        attrs: { placeholder: "请输入查询箱码/底码" },
        model: {
          value: _vm.barCode,
          callback: function ($$v) {
            _vm.barCode = $$v
          },
          expression: "barCode",
        },
      }),
      _c(
        "el-button",
        {
          staticClass: "search_btn",
          attrs: { type: "primary", size: "small", loading: _vm.loding },
          on: { click: _vm.search },
        },
        [_vm._v("查询")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }