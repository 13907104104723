<template>
    <div class="search">
        <el-input placeholder="请输入查询箱码/底码" v-model="barCode" />
        <el-button type="primary" size="small" class="search_btn" @click="search" :loading="loding">查询</el-button>
    </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'SearchCode',
  props: {
    value: String,
  },
  data() {
    return {
      barCode: '',
      loding: false,
    };
  },
  methods: {
    search() {
      if (!this.barCode) { return this.$message.error('请输入查询箱码/底码'); }
      this.loding = true;
      request.get('/cps-mobile/codeTraceability/queryCodeTraceability', { barCode: this.barCode }).then((res) => {
        if (res.success) {
          this.loding = false;
          this.$emit('change', res.result);
        }
      }).catch((err) => {
        this.loding = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
  .search{
    display: flex;
  }
  .search_btn{
    margin-left: 20px;
  }
</style>
