import Form, { formCreate } from '@/found/components/form';
import tableList from './components/table';
import SearchCode from './components/searchCode.vue';

formCreate.component('tableList', tableList);
formCreate.component('SearchCode', SearchCode);

export default {
  extends: Form,
  props: {
    noButton: {
      type: Boolean,
      default: true,
    },
    formConfig: {
      type: Object,
      default() {
        return {
          code: 'view',
        };
      },
    },
  },
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'main_form',
    };
  },
  methods: {
    setRule(item) {
      const v = item;
      if (v.field === 'Search') {
        v.on = {
          ...v.on,
          change: (e) => {
            this.setValue({
              codeTag: e.codeTag,
              codeType: e.codeType,
              productCode: e.productCode,
              productName: e.productName,
              scanningLinkBacktrackingRespVos: e.scanningLinkBacktrackingRespVos || [],
              consumerOpeningInfoRespVos: e.consumerOpeningInfoRespVos || [],
              positiveRewardRecords: e.positiveRewardRecords || [],
              reverseRewardRecords: e.reverseRewardRecords || [],
              consumerRedPacketRewardRecordReqVos: e.consumerRedPacketRewardRecordReqVos || [],
            });
          },
        };
      }
      if (v.type === 'tableList') {
        v.props = {
          ...v.props,
          TableType: v.field,
        };
      }
      return v;
    },
  },
};
