import TablePage from '@/found/components/table_page';

export default {
  extends: TablePage,
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    TableType: String,
  },
  data() {
    return {
      requestType: 'POST',
      tableData: [],
      configs: {
        pagination: false,
      },
      isCalculateHeight: false,
    };
  },
  watch: {
    value(e) {
      this.tableData = e || [];
    },
  },
  created() {
    if (this.TableType === 'reverseRewardRecords') {
      this.getConfigList('positiveRewardRecords');
    } else {
      this.getConfigList(this.TableType);
    }
  },
  methods: {},
};
